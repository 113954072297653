import { FlexContainer, SubmitPageTitle as Title } from "@/components/atoms";
import { EmailUsText } from "@/components/molecules";
import { Typography } from "@gannettdigital/community-hub-components";
import getConfig from "next/config";
import {
  Image,
  IntroContainer,
  List,
  ListItem,
} from "./SubmitPageIntroSection.styled";

const { publicRuntimeConfig } = getConfig();

const IntroSection = () => {
  return (
    <IntroContainer>
      <Image
        alt="Create an Obituary"
        src={`${publicRuntimeConfig.assetsPrefix}/static/images/woman-in-park.svg`}
        width={493}
        height={358}
      />
      <div>
        <Title
          variant="body2"
          textAlign={{ xs: "center" }}
          fontSize={{ md: 50 }}
        >
          Create an Obituary
        </Title>

        <EmailUsText
          marginBottom="16px"
          flexDirection="row"
          linkFontSize={{ md: 18, lg: 24 }}
          label="email1"
        />

        <div>
          <Typography
            variant="body2"
            fontSize="16px"
            fontWeight="bold"
            mb={{ xs: 1, sm: 1.75 }}
          >
            Here&apos;s the information we&apos;ll need:
          </Typography>

          <FlexContainer>
            <List>
              <ListItem>
                Your name, billing address, phone number and email address
              </ListItem>
              <ListItem>The exact text you want to be printed</ListItem>
              <ListItem>
                Photo(s) you would like printed (max of 2 photos)
              </ListItem>
            </List>
            <List>
              <ListItem>
                The date(s) you would like to see your obituary printed
              </ListItem>
              <ListItem>
                The name and phone number of the funeral home taking care of
                arrangements
              </ListItem>
              <ListItem>The name of your paper</ListItem>
            </List>
          </FlexContainer>
        </div>
      </div>
    </IntroContainer>
  );
};

export default IntroSection;
