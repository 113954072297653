export interface GoogleCalendarParameters {
  text: string;
  begin?: string | Date | null;
  end?: string | Date | null;
  details?: string;
  location?: string;
}

/**
 * Generates Google Calendar link for an event
 * @param text Name of the event
 * @param begin Start time of the event
 * @param end End time of the event
 * @param details Event description or details
 * @param location Location of the event
 * @returns The [formatted URL](https://stackoverflow.com/a/19867654)
 */
export const getGoogleCalendarUrl = ({
  text,
  begin,
  end,
  details,
  location,
}: GoogleCalendarParameters): string => {
  let url = "http://www.google.com/calendar/event?action=TEMPLATE";

  url += `&text=${encodeURIComponent(text)}`;

  if (begin && end)
    url += `&dates=${new Date(begin)
      .toISOString()
      .replace(/[-:]/g, "")}/${new Date(end)
      .toISOString()
      .replace(/[-:]/g, "")}`;

  if (details) url += `&details=${encodeURIComponent(details)}`;

  if (location) url += `&location=${encodeURIComponent(location)}`;

  return url;
};
