import { usePubInfo } from "@/hooks/useAppState";
import { INDEX_PAGE_TYPE, useAnalytics } from "@/services/analyticsService";

const basePath = "/obituaries";
const placeObitUrl = fullyQualifiedUrl(`${basePath}/how-to-submit-obituary`);
const directorsUrl = fullyQualifiedUrl(`${basePath}/funeral-directors`);

export type PlaceObitPlacement = "standard" | "rail";

export const usePlaceObit = (placement: PlaceObitPlacement) => {
  const pubInfo = usePubInfo();
  const { trackEventRaw } = useAnalytics();

  const hasPubInfo = (pubInfo?.email && pubInfo?.phone) !== undefined;
  const moduleNamePostFix = placement === "rail" ? " rail" : "";

  const trackPlaceObit = () => {
    trackEventRaw({
      "content-type": INDEX_PAGE_TYPE,
      "event-detail": "For loved ones",
      "event-name": "module_click",
      "event-data": `to: ${placeObitUrl}`,
      "event-module-name": `place obit cta${moduleNamePostFix}`,
    });
  };

  const trackGoDirectorsPage = () => {
    trackEventRaw({
      "content-type": INDEX_PAGE_TYPE,
      "event-detail": "Funeral directors",
      "event-name": "module_click",
      "event-data": `to: ${directorsUrl}`,
      "event-module-name": `go to Funeral Directors cta${moduleNamePostFix}`,
    });
  };

  return {
    trackGoDirectorsPage,
    trackPlaceObit,
    hasPubInfo,
    placeObitUrl,
    directorsUrl,
  };
};

function fullyQualifiedUrl(url: string) {
  if (typeof window === "undefined") {
    return url;
  }

  return new URL(url, window.location.origin).href;
}
