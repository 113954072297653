import React from "react";
import { SkeletonBox, SkeletonBoxProps } from "../SkeletonBox";

export interface SkeletonIconProps
  extends Omit<SkeletonBoxProps, "width" | "height"> {
  size?: number | string;
}

export const SkeletonIcon: React.FC<SkeletonIconProps> = ({
  size = 24,
  ...rest
}) => <SkeletonBox variant="circular" height={size} width={size} {...rest} />;
