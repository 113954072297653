import MuiSkeleton, {
    SkeletonProps as MuiSkeletonProps,
} from "@mui/material/Skeleton";
import Box, { BoxProps } from "@mui/system/Box";

export interface SkeletonBoxProps extends MuiSkeletonProps {
  boxProps?: BoxProps;
}

export const SkeletonBox = ({ boxProps, ...props }: SkeletonBoxProps) => {
  return (
    <Box {...boxProps}>
      <MuiSkeleton {...props} />
    </Box>
  );
};
