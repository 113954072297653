import getFtdUrl from "@/helpers/getFtdUrl";
import { getGoogleCalendarUrl } from "@/helpers/getGoogleCalendarUrl";
import {
  Card,
  CardProps,
  ErrorBoundary,
  ErrorMap,
  formatDate,
  formatTime,
  Link,
  LinkProps,
  Map,
  MapProps,
  setUTCTimeToLocal,
  Skeleton,
  styled,
  Typography,
} from "@gannettdigital/community-hub-components";
import { Colors } from "../contexts";
import { FlowerIcon } from "./icons/FlowerIcon";
import { RoundedCalendarIcon } from "./icons/RoundedCalendarIcon";
import { SkeletonIcon } from "./icons/SkeletonIcon";
import { SkeletonTypography } from "./SkeletonTypography";

export interface ServiceCardProps extends CardProps {
  /**
   * Title of the event
   */
  title: string;
  /**
   * Name of the service
   */
  name: string;
  /**
   * City of the place
   */
  city: string;
  /**
   * State of the place
   */
  state: string;
  /**
   * Country of the place
   */
  country: string;
  /**
   * Zipcode of the place
   */
  zip: string;
  /**
   * Timestamp for the begining of the event
   */
  begin?: string | Date;
  /**
   * Timestamp for the ending of the event
   */
  end?: string | Date;
  /**
   * Type of the event
   * If `all_day` => ignore the time part of the timestamps
   * If `date_time` => display the times
   */
  durationType?: "all_day" | "date_time";
  /**
   * Offset in seconds to UTC
   */
  offset?: number | null;
  /**
   * Url for Get directions link
   */
  getDirectionsUrl?: string;
  /**
   * Additional obituary data for generating Send flowers link
   */
  ftdData?: {
    /**
     * Deceased's first name
     */
    firstName: string;
    /**
     * Deceased's last name
     */
    lastName: string;
    /**
     * Deceased's image
     */
    image: string;
    /**
     * The base FTD url to attach the query parameters
     */
    baseFtdUrl?: string;
  };
  /**
   * Address of the place
   */
  address1?: string;
  /**
   * Second address of the place
   */
  address2?: string;
  /**
   * Additional notes for the event. Would be added as a description, when creating a Google Calendar event.
   */
  notes?: string;
  /**
   * Url for livestream link
   */
  livestreamUrl?: string;
  /**
   * Props, passed to the Map component
   */
  mapProps?: MapProps;
  /**
   * Props, passed to the Send Flowers link
   */
  sendFlowersProps?: LinkProps;
  /**
   * Props, passed to the Get directions link
   */
  directionsProps?: LinkProps;
  /**
   * Props, passed to the Add to calendar link
   */
  calendarProps?: LinkProps;
  /**
   * Props, passed to the Livestream link
   */
  livestreamProps?: LinkProps;
}

export const LoadingServiceCard = () => {
  return (
    <CardWrapper hasMap>
      <MapContainer>
        <Skeleton
          variant="rectangular"
          height="100%"
          width="100%"
          boxProps={{ width: "100%", height: "100%" }}
        />
      </MapContainer>
      <TextContainer>
        <Title variant="body2">
          <Skeleton width="30%" />
        </Title>
        <NameText variant="h2">
          <Skeleton width="70%" />
        </NameText>
        <AddressContainer>
          <AddressText variant="body1">
            <Skeleton width="40%" />
          </AddressText>
          <AddressText variant="body1">
            <Skeleton width="40%" />
          </AddressText>
        </AddressContainer>
        <SkeletonTypography variant="body2" width="60%" />
        <ActionsContainer>
          <Skeleton height={14} width={94} />
          <Box />
          <Skeleton height={14} width={88} />
        </ActionsContainer>
        <DateText>
          <Skeleton width="30%" />
        </DateText>
        <DateText>
          <Skeleton width="35%" />
        </DateText>
        <SkeletonTypography
          variant="body1"
          width={105}
          boxProps={{ mb: 0.5, mt: 1 }}
        />
        <IconLinkContainer>
          <SkeletonIcon boxProps={{ mr: 0.5 }} />
          <Skeleton width={75} />
        </IconLinkContainer>
      </TextContainer>
    </CardWrapper>
  );
};

/**
 * Component, which renders the service card
 */
const ServiceCard = ({
  title,
  name,
  address1,
  address2,
  city,
  state,
  country,
  zip,
  begin,
  end,
  offset,
  durationType,
  notes,
  getDirectionsUrl,
  ftdData,
  livestreamUrl,
  mapProps,
  sendFlowersProps,
  directionsProps,
  calendarProps,
  livestreamProps,
  ...props
}: ServiceCardProps) => {
  const beginWithOffset = setUTCTimeToLocal(begin ?? null, offset);
  const endWithOffset = setUTCTimeToLocal(end ?? null, offset);
  const date = beginWithOffset
    ? formatDate(beginWithOffset, "EEEE, MMMM d, yyyy")
    : null;
  const duration =
    durationType === "date_time" && beginWithOffset
      ? `${formatTime(beginWithOffset)}${
          endWithOffset ? ` - ${formatTime(endWithOffset)}` : ""
        }`
      : null;
  const googleCalendarUrl = getGoogleCalendarUrl({
    text: title,
    begin: beginWithOffset,
    end: endWithOffset,
    details: notes,
    location: address1,
  });
  const sendFlowersUrl = ftdData
    ? getFtdUrl({
        fname: ftdData.firstName,
        lname: ftdData.lastName,
        image: ftdData.image,
        ...(beginWithOffset && { fsdate: new Date(beginWithOffset) }),
        ...(beginWithOffset &&
          durationType === "date_time" && {
            fstime: new Date(beginWithOffset),
          }),
        state,
        zip,
        city,
        add1: address1,
        add2: address2,
        locationName: name,
      })
    : "";

  return (
    <CardWrapper hasMap={!!mapProps} {...props}>
      <MapContainer>
        {mapProps && (
          <ErrorBoundary fallback={<ErrorMap />}>
            <Map {...mapProps} />
          </ErrorBoundary>
        )}
      </MapContainer>
      <TextContainer>
        <Title variant="body2">{title}</Title>
        <NameText variant="h2">{name}</NameText>
        <CTAsContainer>
          {sendFlowersUrl && (
            <IconLinkContainer>
              <IconLink
                href={sendFlowersUrl}
                underline="none"
                target="_blank"
                {...sendFlowersProps}
              >
                <StyledFlowerIcon htmlColor={Colors.white} />
                <Typography variant="h5">Send Flowers</Typography>
              </IconLink>
            </IconLinkContainer>
          )}

          <IconLinkContainer>
            <IconLink
              href={googleCalendarUrl}
              underline="none"
              target="_blank"
              {...calendarProps}
            >
              <StyledRoundedCalendarIcon htmlColor={Colors.white} />
              <Typography variant="h5">Add to calendar</Typography>
            </IconLink>
          </IconLinkContainer>
        </CTAsContainer>

        <AddressContainer>
          {address1 && <Typography variant="body2">{address1}</Typography>}
          {address2 && <Typography variant="body2">{address2}</Typography>}
          <Typography variant="body2">
            {city}, {state}, {country} {zip}
          </Typography>
        </AddressContainer>
        <Typography variant="body2">{notes}</Typography>
        <ActionsContainer>
          {getDirectionsUrl && (
            <Link
              href={getDirectionsUrl}
              variant="body2"
              underline="none"
              {...directionsProps}
            >
              Get directions
            </Link>
          )}
        </ActionsContainer>
        {date && (
          <DateText variant="body2">
            {date}
            {duration ? "," : null}
          </DateText>
        )}
        {duration && <DateText>{duration}</DateText>}
        {livestreamUrl && (
          <StyledLink
            href={livestreamUrl}
            variant="body2"
            underline="none"
            target="_blank"
            {...livestreamProps}
          >
            Livestream
          </StyledLink>
        )}
      </TextContainer>
    </CardWrapper>
  );
};

export default ServiceCard;

const CardWrapper = styled(
  (props: CardProps & { hasMap: boolean }) => <Card {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "hasMap",
  }
)(({ theme, hasMap }) => ({
  width: "100%",

  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: hasMap ? "row-reverse" : "row",
    justifyContent: "space-between",
  },
}));

const MapContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: 160,

  [theme.breakpoints.up("sm")]: {
    width: 595,
    height: 300,
  },
}));

const StyledFlowerIcon = styled(FlowerIcon)({
  width: "38px",
  height: "38px",
});

const StyledRoundedCalendarIcon = styled(RoundedCalendarIcon)({
  width: "38px",
  height: "38px",
});

const TextContainer = styled("div")(({ theme }) => ({
  "& > *": {
    wordBreak: "break-word",
  },

  [theme.breakpoints.up("md")]: {
    width: 375,
  },
}));

const Title = styled(Typography)(({ theme }) => () => ({
  marginBottom: theme.spacing(0.5),
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontSize: 12,
  lineHeight: "14px",
  fontWeight: 600,
  color: theme.palette.all.gray11,

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(1.5),
  },
}));

const NameText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: 18,
  lineHeight: "22.72px",

  [theme.breakpoints.up("sm")]: {
    fontSize: 24,
    lineHeight: "28px",
  },
}));

const AddressContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

const AddressText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  lineHeight: "28px",

  [theme.breakpoints.up("sm")]: {
    fontSize: 18,
    lineHeight: "29px",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  variant: "body2",
  color: theme.palette.all.blue2,
}));

const IconLink = styled(Link)({
  variant: "body2",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& > *:not(:last-child)": {
    marginRight: "7.5px",
  },
});

const ActionsContainer = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.up("sm")]: {
    marginBottom: theme.spacing(1.25),
  },
}));

const DateText = styled(Typography)({
  lineHeight: "20px",
});

const CTAsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),

  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },

  "& > *:not(:last-child)": {
    marginRight: "20px",
  },
}));

const IconLinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.all.black,
  "& > svg": {
    marginRight: theme.spacing(0.5),
  },
}));

const Box = styled("div")({
  width: 11,
});
