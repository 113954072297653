import { usePlaceObit } from "@/hooks/usePlaceObit";
import { ClickAwayListener } from "@mui/base";
import React, { useState } from "react";
import SignInMenu from "../SignInMenu/SignInMenu";
import { Button, Container, SignInMenuWrapper } from "./TopNav.styled";

const TopNav: React.FC = () => {
  const [open, setOpen] = useState(false);

  const { trackPlaceObit, hasPubInfo, placeObitUrl } = usePlaceObit("standard");

  if (!hasPubInfo) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Container>
        {hasPubInfo && (
          <div>
            <Button
              disableElevation
              href={placeObitUrl}
              onClick={trackPlaceObit}
            >
              Place an Obituary
            </Button>
          </div>
        )}

        <SignInMenuWrapper>
          <SignInMenu open={open} onToggle={() => setOpen(!open)} />
        </SignInMenuWrapper>
      </Container>
    </ClickAwayListener>
  );
};

export default TopNav;
