import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const FlowerIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 39 39" {...props}>
      <circle cx="19.207" cy="19.8806" r="19" fill="#EAF6FF" />
      <path
        d="M21.8484 31.0925V27.5105"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4119 18.3044C13.4119 18.3044 17.54 19.5014 18.0786 21.7175C18.6172 23.9325 19.5746 27.7045 21.788 27.7045C24.0025 27.7045 24.8399 25.0705 24.8399 25.0705V18.4834L13.4119 18.3044Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4119 18.3044C13.4119 18.3044 17.54 19.5014 18.0786 21.7175C18.6172 23.9325 19.5746 27.7045 21.788 27.7045C24.0025 27.7045 24.8399 25.0705 24.8399 25.0705V18.4834L13.4119 18.3044Z"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8396 25.0696C26.0367 22.3756 27.15 18.6276 26.8142 15.6696C26.4894 12.8146 23.7623 10.8806 21.0102 10.8806C18.2582 10.8806 14.0701 12.3766 12.0955 12.3766C10.1208 12.3766 8.56592 12.0176 8.56592 12.0176C9.58221 18.6636 15.9847 19.0826 19.7541 20.5196C20.1239 20.6606 20.4636 20.8216 20.7874 20.9896C20.9413 18.4796 21.1302 15.2676 21.1302 14.5916C21.1302 13.6946 22.5062 13.8736 22.5062 14.5326C22.5062 15.0116 22.823 19.9276 22.9959 22.5686C24.3189 23.8396 24.8396 25.0696 24.8396 25.0696Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8396 25.0696C26.0367 22.3756 27.15 18.6276 26.8142 15.6696C26.4894 12.8146 23.7623 10.8806 21.0102 10.8806C18.2582 10.8806 14.0701 12.3766 12.0955 12.3766C10.1208 12.3766 8.56592 12.0176 8.56592 12.0176C9.58221 18.6636 15.9847 19.0826 19.7541 20.5196C20.1239 20.6606 20.4636 20.8216 20.7874 20.9896C20.9413 18.4796 21.1302 15.2676 21.1302 14.5916C21.1302 13.6946 22.5062 13.8736 22.5062 14.5326C22.5062 15.0116 22.823 19.9276 22.9959 22.5686C24.3189 23.8396 24.8396 25.0696 24.8396 25.0696Z"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1299 14.5923C21.1299 15.2673 20.941 18.4802 20.7871 20.9902C21.6885 21.4602 22.421 22.0162 22.9956 22.5692C22.8227 19.9272 22.5059 15.0123 22.5059 14.5323C22.5059 13.8733 21.1299 13.6943 21.1299 14.5923Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1299 14.5923C21.1299 15.2673 20.941 18.4802 20.7871 20.9902C21.6885 21.4602 22.421 22.0162 22.9956 22.5692C22.8227 19.9272 22.5059 15.0123 22.5059 14.5323C22.5059 13.8733 21.1299 13.6943 21.1299 14.5923Z"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
