import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const RoundedCalendarIcon: React.FC<SvgIconProps> = ({
  disabled,
  ...props
}) => {
  if (disabled) {
    return (
      <SvgIcon
        width="39"
        height="38"
        viewBox="0 0 39 38"
        disabled
        {...props}
        htmlColor="white"
      >
        <circle cx="19.75" cy="19" r="19" fill="#F0F0F0" />
        <rect
          x="11.25"
          y="10.5"
          width="17"
          height="17"
          rx="1.5"
          fill="white"
          stroke="#C2C2C2"
        />
        <line x1="11.75" y1="15.5" x2="27.75" y2="15.5" stroke="#C2C2C2" />
        <line
          x1="16.4375"
          y1="8.9375"
          x2="16.4375"
          y2="11.9375"
          stroke="#C2C2C2"
          strokeLinecap="round"
        />
        <line
          x1="23.3125"
          y1="8.9375"
          x2="23.3125"
          y2="11.9375"
          stroke="#C2C2C2"
          strokeLinecap="round"
        />
        <circle cx="15.1875" cy="19" r="0.75" fill="#C2C2C2" />
        <circle cx="19.6875" cy="19" r="0.75" fill="#C2C2C2" />
        <circle cx="24.1875" cy="19" r="0.75" fill="#C2C2C2" />
        <circle cx="15.1875" cy="23" r="0.75" fill="#C2C2C2" />
        <circle cx="19.6875" cy="23" r="0.75" fill="#C2C2C2" />
        <circle cx="24.1875" cy="23" r="0.75" fill="#C2C2C2" />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width="39"
      height="38"
      viewBox="0 0 39 38"
      {...props}
      color="primary"
    >
      <circle cx="19.75" cy="19" r="19" fill="#EAF6FF" />
      <rect
        x="11.25"
        y="10.5"
        width="17"
        height="17"
        rx="1.5"
        fill="white"
        stroke="#303030"
      />
      <line x1="11.75" y1="15.5" x2="27.75" y2="15.5" stroke="#303030" />
      <line
        x1="16.4375"
        y1="8.9375"
        x2="16.4375"
        y2="11.9375"
        stroke="#303030"
        strokeLinecap="round"
      />
      <line
        x1="23.3125"
        y1="8.9375"
        x2="23.3125"
        y2="11.9375"
        stroke="#303030"
        strokeLinecap="round"
      />
      <circle cx="15.1875" cy="19" r="0.75" fill="#303030" />
      <circle cx="19.6875" cy="19" r="0.75" fill="#303030" />
      <circle cx="24.1875" cy="19" r="0.75" fill="#303030" />
      <circle cx="15.1875" cy="23" r="0.75" fill="#303030" />
      <circle cx="19.6875" cy="23" r="0.75" fill="#303030" />
      <circle cx="24.1875" cy="23" r="0.75" fill="#303030" />
    </SvgIcon>
  );
};
