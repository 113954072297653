import { styled } from "@gannettdigital/community-hub-components";
import MuiSvgIcon, {
    SvgIconProps as MuiSvgIconProps,
} from "@mui/material/SvgIcon";

export interface SvgIconProps extends MuiSvgIconProps {
  variant?: "basic" | "outlined" | "filled";
  disabled?: boolean;
}

export const SvgIcon = styled(
  (props: SvgIconProps) => <MuiSvgIcon {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "variant" && prop !== "disabled",
  }
)(({ theme, variant, disabled }) => ({
  cursor: disabled ? "default" : "pointer",
  border:
    variant === "outlined"
      ? `1px solid ${theme.palette.all.gray1}`
      : variant === "filled"
      ? `1px solid ${theme.palette.all.white}`
      : "none",
  backgroundColor: variant === "filled" ? "rgba(48, 48, 48, 0.5)" : "none",
}));
