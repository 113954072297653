import { Typography } from "@gannettdigital/community-hub-components";
import { TypographyVariant } from "@mui/material";
import React from "react";
import { SkeletonBox, SkeletonBoxProps } from "./SkeletonBox";

export interface SkeletonTypographyProps
  extends Omit<SkeletonBoxProps, "variant"> {
  /**
   * Variant of the typography
   */
  variant?: TypographyVariant;
}

export const SkeletonTypography: React.FC<SkeletonTypographyProps> = ({
  variant,
  ...props
}) => (
  <Typography variant={variant}>
    <SkeletonBox variant="text" {...props} />
  </Typography>
);
